import * as React from 'react';
import { useState } from 'react';
import { Dialog } from '@material-ui/core';
import OrderDetail from '../../components/OrderDetail';
import { statusIds } from '../../data/constants';
import { ZoomIn, Phone, Home } from '@material-ui/icons';
import FieldType from '../../components/FieldType';
import FieldStatus from '../../components/FieldStatus';
import ButtonIncident from '../../components/ButtonIncident';
import './history.css';

import {
    //   FIELDS
    TextField,
    FunctionField,
    //   INPUTS
    SelectInput,
    TextInput,
    DateInput,
    //   MISC
    List,
    Datagrid,
    Pagination
} from 'react-admin';
import ButtonSimple from '../../components/ButtonSimple';
import { withTranslation } from 'react-i18next';

const MyPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;

let daysbefore = new Date();
daysbefore.setDate(daysbefore.getDate() - 3);

const filterDefaultValues = {
    search_field: 'user_detail.first_name',
    search_value: '',
    order_status: 'all',
    order_type: 'both',
    end_date: '',
    start_date: daysbefore,
    order_status_id: ''
};

const ListHistory = props => {
    const t = props.t;
    const orderFilters = [
        <SelectInput
            source='search_field'
            choices={[
                { id: 'user_detail.first_name', name: t('labelUser') },
                { id: 'store_detail.name', name: t('labelRestaurant') },
                { id: 'unique_id', name: t('labelId') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <TextInput source='search_value' label='search' alwaysOn resettable />,
        <SelectInput
            source='order_status_id'
            choices={[
                { id: '', name: t('labelAll') },
                { id: 3, name: t('labelRejected') },
                { id: 10, name: t('labelCompleted') }
            ]}
            allowEmpty={false}
        />,
        <SelectInput
            source='order_type'
            choices={[
                { id: 'both', name: t('labelBoth') },
                { id: 'false', name: t('labelASAP') },
                { id: 'true', name: t('labelPlanified') }
            ]}
            allowEmpty={false}
        />,
        <DateInput source='start_date' label={t('labelStartDate')} />,
        <DateInput source='end_date' label={t('labelEndDate')} />
    ];
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogUser, setOpenDialogUser] = useState(false);
    const [selectUser, setSelectUser] = useState(null);

    return (
        <div className='containerAllHistoryListContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('titleHistoryList')}</div>
                <List
                    {...props}
                    title={t('titleHistoryList')}
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    filters={orderFilters}
                    filterDefaultValues={filterDefaultValues}
                >
                    <Datagrid
                        rowClick={(id, basePath, record) => {
                            setSelectedOrder(record);
                            setOpenDialog(true);
                        }}
                    >
                        <TextField source='unique_id' label={t('labelId')} sortable={false} />
                        <FieldType source='is_schedule_order' label={t('labelType')} />

                        <FunctionField
                            label={t('labelLastname')}
                            render={record => `${record.user_detail.first_name} ${record.user_detail.last_name}`}
                            sortable={false}
                        />
                        <FunctionField
                            label={t('labelDeliverymen')}
                            render={record =>
                                record.provider_detail
                                    ? `${record.provider_detail.first_name} ${record.provider_detail.last_name}`
                                    : ''
                            }
                            sortable={false}
                        />
                        <FunctionField
                            label={t('labelCoordinateClient')}
                            render={record => (
                                <div className='buttonCoordinateClient'>
                                    <ButtonSimple
                                        action={() => {
                                            setOpenDialogUser(true);
                                            setSelectUser(record);
                                        }}
                                        text={t('labelDetail')}
                                        Icon={ZoomIn}
                                    />
                                </div>
                            )}
                        />
                        <TextField source='store_detail.name' label={t('labelRestaurant')} sortable={false} />
                        <FunctionField
                            label={t('labelDateAndHour')}
                            render={record => {
                                const dateTesting = new Date(record.completed_date_in_city_timezone);
                                var userTimezoneOffset = dateTesting.getTimezoneOffset() * 60000;
                                let dateWitfOffset = new Date(dateTesting.getTime() + userTimezoneOffset);
                                var options = {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                };
                                let dateToDisplay = dateWitfOffset.toLocaleString('fr-FR', options);
                                return (dateToDisplay);
                            }}
                        />
                        <TextField source='order_payment_detail.total' label={t('labelAmount')} sortable={false} />
                        <FieldStatus label={t('labelStatus')} />
                        <FunctionField
                            label={t('labelStatusId')}
                            render={record => statusIds[record.order_status_id]}
                            sortable={false}
                        />
                        <FunctionField
                            label={t('labelIncident')}
                            render={record => <ButtonIncident orderId={record.id} orderUniqueId={record.unique_id} />}
                            sortable={false}
                        />
                    </Datagrid>
                </List>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={false}>
                    <OrderDetail t={t} data={selectedOrder} />
                </Dialog>
                <Dialog open={openDialogUser} onClose={() => setOpenDialogUser(false)} maxWidth={false}>
                    <div className='historyPopupClient'>
                        <div className='historyPopupClientRow'>
                            {' '}
                            <Phone />
                            {t('textPhone')}{selectUser ? selectUser.user_detail.phone : null}
                        </div>
                        <div className='historyPopupClientRow'>
                            {' '}
                            <Home />
                            {t('textAdress')}{selectUser ? selectUser.cart_detail.destination_addresses[0].address : null}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default withTranslation()(ListHistory);
