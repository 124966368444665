import { useRecordContext } from 'react-admin';
import './FieldStatus.css';
import { deliveryStatusTable } from '../data/constants';
import { colors } from '../config';

const colorsStatus = {
    1: colors.grey,
    3: colors.green,
    5: colors.blue,
    7: colors.red,
    9: colors.grey,
    11: colors.blue,
    13: colors.blue,
    15: colors.green,
    17: colors.green,
    19: colors.green,
    21: colors.green,
    23: colors.green,
    25: colors.green,
    101: colors.red,
    103: colors.red,
    104: colors.red,
    105: colors.red,
    109: colors.red,
    112: colors.red,
    107:colors.red
};

const FieldType = props => {
    const record = useRecordContext();
    let st = props.basePath === '/deliveries' ? record.order_detail.order_status : record.order_status;
    if (props.source === 'delivery_status') {
        st = record.delivery_status;
    }

    return (
        <div className='fieldStatusContainer' style={{ backgroundColor: colorsStatus[st] || 'grey' }}>
            <span>{deliveryStatusTable[st]}</span>
        </div>
    );
};

export default FieldType;
