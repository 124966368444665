import { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import './ButtonCallApi.css';
import { useRecordContext } from 'react-admin';

const ButtonSimple = ({ action, text, Icon }) => {
    const record = useRecordContext();
    const [bubble, setBubble] = useState(false);

    return (
        <button
            className='btn first'
            onClick={e => {
                e.stopPropagation();
                action();
            }}
            onMouseEnter={() => {
                setBubble(true);
            }}
            onMouseLeave={() => {
                setBubble(false);
            }}
        >
            {!bubble ? <Icon /> : <div className='insideButton'> {text}</div>}
        </button>
    );
};
export default ButtonSimple;
