import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { user_page_type, search_value, search_field } = params.filter;

        return httpClient(`${apiUrl}/admin/user_list_search_sort`, {
            method: 'POST',
            body: JSON.stringify({
                number_of_rec: perPage,
                page,
                search_value: search_value || '',
                search_field: search_field || 'first_name',
                user_page_type
            })
        }).then(({ json }) => {
            const data = json.users
                ? json.users.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * perPage
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(apiUrl + '/admin/get_user_detail', {
            method: 'POST',
            body: JSON.stringify({
                user_id: params.id
            })
        })
            .then(({ json }) => {
                json.user.id = json.user._id;
                return {
                    data: json.user
                };
            })
            .catch(e => {
                console.log('api error');
            });
    },

    update: (resource, params) => {
        params.data.user_id = params.id;
        return httpClient(apiUrl + '/admin/update_user', {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => {
            json.user.id = params.id;
            return { data: json.user };
        });
    },
    approveDecline: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/approve_decline_user', {
            method: 'POST',
            body: JSON.stringify({
                user_id: record.id,
                user_page_type: otherParameters
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    addWallet: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/add_wallet', {
            method: 'POST',
            body: JSON.stringify({
                type: 7,
                user_id: record._id,
                wallet: otherParameters.input
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    sendNotif: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/send_notification', {
            method: 'POST',
            body: JSON.stringify({
                type: 7,
                user_id: record._id,
                content: otherParameters.input
            })
        }).then(({ json }) => {
            return { data: json };
        });
    }
};
