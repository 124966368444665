import { TextField, TextInput, SimpleForm, SaveButton, Toolbar, SelectInput, required } from 'react-admin';
import { EditDialog } from '@react-admin/ra-form-layout';
import './Incident.css';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const EditTitle = ({ record,t }) => {
    return <span>{t('titleIncidentNumber')} {record && record.order_unique_id}</span>;
};

const CustomToolbar = props => (
    <div className='saveButton'>
        <Toolbar {...props}>
            <SaveButton icon={<></>} label={'enregistrer'} />
        </Toolbar>
    </div>
);

const MyChildrenContainerComponent = props => {
    const t = props.t;
    const validateNotEmpty = [required(t('fieldMandatory'))];
    return (
        <div className='incidentContainer'>
            <div className='rowFlex'>
                <div className='incidentBlock'>
                    <div className='rowInput'>
                        <TextInput
                            className='inputIncident'
                            validate={validateNotEmpty}
                            required
                            source='issue'
                            multiline
                        />
                    </div>
                    <div className='rowInput'>
                        <TextInput
                            className='inputIncident'
                            validate={validateNotEmpty}
                            required
                            source='resolution'
                            multiline
                        />
                    </div>
                    <div className='rowInput'>
                        <SelectInput
                            required
                            validate={validateNotEmpty}
                            source='accountable'
                            className='inputIncident'
                            choices={[
                                { id: '0', name: t('labelRestaurant') },
                                { id: '1', name: t('labelUser') },
                                { id: '2', name: t('labelProvider') }
                            ]}
                        />
                    </div>
                    <div className='rowInput'>
                        <SelectInput
                            className='inputIncident'
                            required
                            validate={validateNotEmpty}
                            source='status'
                            choices={[
                                { id: '0', name: t('labelPending') },
                                { id: '1', name: t('labelIncidentCompleted') }
                            ]}
                        />
                    </div>
                </div>

                <div className='incidentBlock'>
                    <div className='rowInput'>
                        <div>
                            <span>{t('labelAddCutToProvider')} : </span>
                            <TextField source='added_cut_to_provider' label={'labelAddCutToProvider'} />
                        </div>
                        <TextInput className='inputIncident' defaultValue={null} source='new_cut_to_provider' />
                    </div>

                    <div className='rowInput'>
                        <div>
                            <span>{t('labelAddCutToUser')} : </span>
                            <TextField source='added_cut_to_user' />
                        </div>

                        <TextInput className='inputIncident' defaultValue={null} source='new_cut_to_user' />
                    </div>
                    <div className='rowInput'>
                        <div>
                            <span>{t('labelAddCutToStore')} : </span>
                            <TextField source='added_cut_to_store' />
                        </div>

                        <TextInput className='inputIncident' defaultValue={null} source='new_cut_to_store' />
                    </div>
                </div>
            </div>
        </div>
    );
};

const IncidentEdit = props => {
    const t=props.t
    const validateIncident = async values => {
        const errors = {};
        if (values.status === undefined) {
            errors.status = t('errorInvalidField');
        }
        if (values.accountable === undefined) {
            errors.accountable = t('errorInvalidField');
        }
        if (values.issue === undefined) {
            errors.issue = t('errorInvalidField');
        }
        if (values.resolution === undefined) {
            errors.resolution = t('errorInvalidField');
        }

        return errors;
    };

    const transform = data => {
        data.add_cut_to_provider = data.new_cut_to_provider ||0
        data.add_cut_to_store = data.new_cut_to_store ||0
        data.add_cut_to_user = data.new_cut_to_user ||0
        return data;
    };

    return (
        <EditDialog
            transform={transform}
            className='editDialogAllContainer'
            successMessage={t('messageSuccesIncidentUpdate')}
            mutationMode='pessimistic'
            {...props}
            title={<EditTitle t={t} />}
            maxWidth={false}
        >
            <SimpleForm validate={validateIncident} toolbar={<CustomToolbar />}>
                <MyChildrenContainerComponent {...props} />
            </SimpleForm>
        </EditDialog>
    );
};

export default withTranslation()(IncidentEdit);
