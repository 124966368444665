// Props

// text="cancel delivery"
// successMessage="canceled"
// action="cancel"
// successAction=
// failAction
// otherParameters
// resource if needed

import * as React from 'react';
import { useState } from 'react';
import './ButtonCallApi.css';
import { Dialog, DialogTitle } from '@material-ui/core';
import ButtonCallApi from './ButtonCallApi';

const ButtonCallApiInput = ({
    resource,
    action,
    successAction,
    failAction,
    successMessage,
    text,
    otherParameters,
    Icon
}) => {
    const [bubble, setBubble] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [inputText, setInputText] = useState('');

    return (
        <div>
            <button
                className='btn first'
                onClick={e => {
                    setOpenModal(true);
                }}
                onMouseEnter={() => {
                    setBubble(true);
                }}
                onMouseLeave={() => {
                    setBubble(false);
                }}
            >
                {!bubble ? <Icon /> : <div className='insideButton'> {text}</div>}
            </button>
            <Dialog
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
            >
                <div className='dialogButton'>
                    <DialogTitle>{text}</DialogTitle>
                    <input
                        className='inputButton'
                        value={inputText}
                        onChange={e => {
                            setInputText(e.target.value);
                        }}
                    />
                    <ButtonCallApi
                        resource={resource}
                        action={action}
                        successAction={() => {
                            setOpenModal(false);
                        }}
                        failAction={() => {
                            setOpenModal(false);
                        }}
                        successMessage={successMessage}
                        text={text}
                        otherParameters={{ ...otherParameters, input: inputText }}
                        Icon={Icon}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default ButtonCallApiInput;
