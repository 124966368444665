import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import OrderDetail from '../../components/OrderDetail';
import FieldType from '../../components/FieldType';
import FieldStatus from '../../components/FieldStatus';
import { Cancel } from '@material-ui/icons';
import { useListContext } from 'react-admin';
import { RELOAD_ORDER_TIME } from '../../config';
import './order.css';

import {
    //   FIELDS
    TextField,
    FunctionField,
    DateField,
    //   INPUTS
    SelectInput,
    TextInput,
    //   MISC
    List,
    Datagrid,
    Pagination
} from 'react-admin';
import ButtonSimple from '../../components/ButtonSimple';
import DialogConfirmCancel from '../../components/DialogConfirmCancel';
import { withTranslation } from 'react-i18next';

const filterDefaultValues = {
    search_field: 'user_detail.first_name',
    order_status: 'all',
    order_type: 'both'
};

const MyPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;

const ListChild = ({ setSelectedOrder, setOpenDialog, setOpenCancelDialog, setCancelRecord, t }) => {
    const { refetch } = useListContext();
    const [reload, setReload] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            refetch();
            setReload(!reload);
        }, RELOAD_ORDER_TIME);
    }, [reload]);
    return (
        <Datagrid
            rowClick={(id, basePath, record) => {
                setSelectedOrder(record);
                setOpenDialog(true);
            }}
        >
            <TextField source='unique_id' label={t('labelId')} sortable={false} />
            <FieldType source='is_schedule_order' label={t('labelType')} />
            <FunctionField
                label={t('labelHourCreation')}
                render={record => (
                    <DateField
                        source='created_at'
                        showTime
                        sortable={false}
                        locales='fr'
                        options={{
                            day: '2-digit',
                            month: 'long',
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZone: record.timezone
                        }}
                    />
                )}
            />
            <FunctionField
                label={t('labelLastname')}
                render={record => `${record.user_detail.first_name} ${record.user_detail.last_name}`}
                sortable={false}
            />
            <TextField source='store_detail.name' label={t('labelRestaurant')} sortable={false} />

            <FieldStatus label={t('labelStatus')} />
            <FunctionField
                label={t('labelAmount')}
                render={record =>
                    `${record.order_payment_detail.total} ${record.order_payment_detail.order_currency_code}`
                }
                sortable={false}
            />
            <FunctionField
                label={t('labelPayment')}
                render={record => {
                    if (record.order_payment_detail.is_paid_from_wallet) {
                        return t('labelWallet');
                    }
                    if (record.order_payment_detail.is_payment_mode_cash) {
                        return t('labelCash');
                    }
                }}
                sortable={false}
            />
            <FunctionField
                label={t('labelAction')}
                render={record => (
                    <div className='buttonCallApiContainer'>
                        <ButtonSimple
                            action={() => {
                                setOpenCancelDialog(true);
                                setCancelRecord(record);
                            }}
                            text={t('textCancelOrder')}
                            Icon={Cancel}
                        />
                    </div>
                )}
                sortable={false}
            />
        </Datagrid>
    );
};

const OrderList = props => {
    const t = props.t;

    const historyFilters = [
        <SelectInput
            source='search_field'
            choices={[
                { id: 'user_detail.first_name', name: t('labelUser') },
                { id: 'store_detail.name', name: t('labelRestaurant') },
                { id: 'unique_id', name: t('labelId') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <TextInput source='search_value' label={t('labelSearch')} alwaysOn resettable />,
        <SelectInput
            source='order_status'
            choices={[
                { id: 'all', name: t('labelAll') },
                { id: 1, name: t('choiceNewOrderCreated') },
                { id: 3, name: t('choiceAccepted') },
                { id: 5, name: t('choiceStartedPreparing') },
                { id: 7, name: t('choiceOrderReady') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <SelectInput
            source='order_type'
            choices={[
                { id: 'both', name: t('labelBoth') },
                { id: 'false', name: t('labelASAP') },
                { id: 'true', name: t('labelPlanified') }
            ]}
            alwaysOn
            allowEmpty={false}
        />
    ];
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [cancelRecord, setCancelRecord] = useState(null);

    return (
        <div className='containerAllOrdersListContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('titleOrderList')}</div>
                <List
                    {...props}
                    title={t('titleOrderList')}
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    filters={historyFilters}
                    filterDefaultValues={filterDefaultValues}
                >
                    <ListChild
                        t={t}
                        setSelectedOrder={setSelectedOrder}
                        setCancelRecord={setCancelRecord}
                        setOpenDialog={setOpenDialog}
                        setOpenCancelDialog={setOpenCancelDialog}
                    />
                </List>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={false}>
                    <OrderDetail t={t} data={selectedOrder} />
                </Dialog>
                <Dialog
                    open={openCancelDialog}
                    onClose={() => {
                        setOpenCancelDialog(false);
                    }}
                    maxWidth={false}
                >
                    <DialogConfirmCancel t={t} setOpenCancelDialog={setOpenCancelDialog} cancelRecord={cancelRecord} />
                </Dialog>
            </div>
        </div>
    );
};

export default withTranslation()(OrderList);
