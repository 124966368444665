import { useState, useEffect, useContext } from 'react';
import { DialogTitle } from '@material-ui/core';
import {
    DataProviderContext,
    Loading,
    useList,
    ListContextProvider,
    TextField,
    FunctionField,
    TextInput,
    BooleanInput,
    BooleanField,
    SelectInput,
    useNotify
} from 'react-admin';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const MyVehiculesEdit = props => {
    const notify = useNotify();

    return (
        <RowForm
            {...props}
            onSuccess={data => {
                notify('provider vehicule updated', 'info', null, true);
                props.closeDialog();
            }}
            undoable={false}
        >
            <BooleanInput source='is_approved' label='' />
            <TextInput source='vehicle_name' label='name' style={{ width: '130px' }} />
            <TextInput source='vehicle_plate_no' label='plate' style={{ width: '100px' }} />
            <TextInput source='vehicle_year' label='year' style={{ width: '100px' }} />
            <TextInput source='vehicle_model' label='model' style={{ width: '100px' }} />
            <TextInput source='vehicle_color' label='color' style={{ width: '100px' }} />
            <SelectInput
                source='Type'
                choices={props.record.vehicle_detail.map(v => {
                    return { id: v._id, name: v.vehicle_name };
                })}
                allowEmpty={false}
            />
            ,
        </RowForm>
    );
};

const MyVehiculesGrid = ({ data, vehiculesTypes, closeDialog }) => {
    data.forEach(e => {
        e.id = e._id;
    });
    const listContext = useList({
        data: data,
        ids: data.map(e => e.id),
        basePath: '/deliverymenVehicules',
        resource: 'deliverymenVehicules'
    });

    return (
        <ListContextProvider value={listContext}>
            <EditableDatagrid
                undoable
                noDelete
                editForm={<MyVehiculesEdit resource='deliverymenVehicules' closeDialog={closeDialog} />}
            >
                >
                <BooleanField source='is_approved' label='Approuvé' sortable={false} />
                <TextField source='vehicle_name' label='name' sortable={false} />
                <TextField source='vehicle_plate_no' label='plate' sortable={false} />
                <TextField source='vehicle_year' label='year' sortable={false} />
                <TextField source='vehicle_model' label='model' sortable={false} />
                <TextField source='vehicle_color' label='color' sortable={false} />
                <FunctionField
                    label='type'
                    render={r =>
                        vehiculesTypes[vehiculesTypes.findIndex(v => v._id === r.admin_vehicle_id)].vehicle_name
                    }
                />
            </EditableDatagrid>
        </ListContextProvider>
    );
};

const DeliveryManVehicules = ({ deliveryManId, closeDialog }) => {
    const dataProvider = useContext(DataProviderContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(false);

    useEffect(() => {
        dataProvider
            .customApiCall('deliverymen', {
                action: 'getDeliveryManVehicules',
                deliveryManId
            })
            .then(({ data }) => {
                setLoading(false);
                if (!data.success) {
                } else {
                    setData(data);
                }
            })
            .catch(error => {
                setLoading(false);
                console.log('api error', error);
            });
    }, []);

    if (loading || !data) {
        return (
            <div>
                <Loading />
            </div>
        );
    } else {
        return (
            <>
                <DialogTitle>Liste des véhicules</DialogTitle>
                <MyVehiculesGrid
                    data={data.provider_vehicles}
                    vehiculesTypes={data.vehicles}
                    closeDialog={closeDialog}
                />
            </>
        );
    }
};

export default DeliveryManVehicules;
