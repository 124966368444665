import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { search_field, search_value, order_status, order_type } = params.filter;
        return httpClient(`${apiUrl}/admin/orders_incidents_list`, {
            method: 'POST',
            body: JSON.stringify({
                end_date: '',
                start_date: '',
                page: page,
                search_field: search_field || 'user_detail.first_name',
                search_value: search_value || '',
                user_type: 0,
                wallet_comment_id: 0
            })
        }).then(({ json }) => {
            const data = json.wallet
                ? json.wallet.map(resource => ({
                      ...resource,
                      //   id: resource._id
                      // ATTENTION ! we use here OrderId as the main Id for the Incidents, because the research is made with order_Id
                      id: resource.order_id
                  }))
                : [];
            return {
                data,
                total: json.pages * 10
            };
        });
    },
    getOne: (resource, params) => {
        return httpClient(apiUrl + '/admin/get_orders_incidents_detail', {
            method: 'POST',
            body: JSON.stringify({
                order_id: params.id
            })
        }).then(({ json }) => {
            json.orders_incidents_detail.id = json.orders_incidents_detail.order_id;
            return {
                data: json.orders_incidents_detail
            };
        });
    },
    update: (resource, params) => {
        return httpClient(apiUrl + '/admin/add_edit_incidents', {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => {
            params.data.id = params.data.order_id;
            return {
                data: params.data
            };
        });
    },
    create: (resource, params) => {
        return httpClient(apiUrl + '/admin/add_edit_incidents', {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => {
            params.data.id = params.data.order_id;
            return {
                data: params.data
            }
        })
        .catch(err=> {
            console.log(err,'api error')
        });
    },
    getOrdersIncidentsDetail: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/get_orders_incidents_detail', {
            method: 'POST',
            body: JSON.stringify({
                order_id: otherParameters
            })
        }).then(({ json }) => {
            return { data: json };
        });
    }
};
