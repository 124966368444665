export const TRANSLATIONS_FR = {
    deliveryTitle: 'Liste des livraisons',
    labelId: 'Id',
    labelDetail: 'Détail',
    labelType: 'Type',
    labelRecuperationEstimated: 'Récupération estimée',
    labelStatusOrder: 'Statut commande',
    labelStatusOfTheOrder: 'Statut de la commande',
    labelClient: 'Client',
    labelDeliverymen: 'Livreur',
    labelRestaurant: 'Restaurant',
    textSelectDM: 'Select DM',
    labelAction: 'Action',
    textCancelDelivery: 'Annuler livraison',
    labelCancel: 'Annuler',
    textCancelOrder:'Annuler commande',
    labelUser: 'Utilisateur',
    labelSearchField: 'Champ de recherche',
    labelSearch: 'Chercher',
    labelFirstname: 'Prénom',
    labelLastname: 'Nom',
    labelEmail: 'Email',
    labelPhone: 'Numéro de téléphone',
    labelPhone2:'Téléphone',
    labelTown: 'Ville',
    labelDeviceType: "Type d'appareil",
    labelAppVersion: "Version de l'appareil",
    labelPhoneCode: 'Code téléphone',
    labelCountry: 'Pays',
    labelCity: 'Ville',
    deliverymanTitle: 'Liste des livreurs',
    labelOnline: 'En ligne',
    labelApproved: 'Approuvé',
    labelUnapprouved: 'Non approuvé',
    labelAll: 'Tous',
    labelRejected: 'rejetées',
    labelCompleted: 'complétées',
    labelBoth: 'les deux',
    labelASAP: 'ASAP',
    labelPlanified: 'Planifiée',
    labelStartDate: 'Date de début',
    labelEndDate: 'Date de sortie',
    titleHistoryList: 'Historique des commandes',
    labelCoordinateClient: 'Coordonnées du client',
    labelDateAndHour: 'Date et heure',
    labelAmount: 'Montant',
    labelStatus: 'Statut',
    labelStatusId: 'Statut ID',
    labelAdress: 'Adresse',
    labelIncident: 'Incident',
    textPhone: 'Téléphone : ',
    textAdress: 'Adresse : ',
    labelOderId: 'Commande #',
    labelPending: 'En cours',
    labelIncidentCompleted: 'Fini',
    labelProvider: 'Fournisseur',
    fieldMandatory: 'Champ obligatoire',
    labelAddCutToProvider: 'Montant livreur',
    labelAddCutToStore: 'Montant restaurant',
    labelAddCutToUser: "Montant client",
    errorInvalidField: 'Champ invalide',
    messageSuccesIncidentUpdate: 'Incident modifié avec succès !',
    titleCreateIncident: 'Créez un incident',
    messageSuccesIncidentCreate: 'Incident créé avec succès',
    labelIssue: 'Problème',
    labelResolution: 'Résolution',
    labelAccountable: 'Responsable',
    choiceNewOrderCreated:'Nouvelle commande créée',
    choiceAccepted:'Acceptée',
    choiceStartedPreparing:'Préparation commencé',
    choiceOrderReady:'Commande prête',
    indicatorOrderDeliveryMen:'Commande(s) / livreur(s)',
    indicatorDeliveryMenWithoutOrder:'Livreur(s) sans commandes',
    indicatorDeliveryMenOnlineAndActive:'Livreur(s) en lignes et actif(s)',
    indicatorDeliveryMenOnlineAndInactive:'Livreur(s) en lignes et inactif(s)',
    userListTitle:'Liste des utilisateurs',
    walletTitle:'Historique wallets',
    titleOrderList:'Liste des commandes',
    labelHourCreation:'Heure de création',
    labelPayment:'Paiement',
    labelWallet:'Portefeuille',
    labelCash:'Cash',
    labelVisible:'Visible',
    labelActive:'Actif',
    labelProfit:'Profit (%)',
    labelAdminProfitModeOnStore:'mode de profit créé par le restaurant',
    labelPeriod:'Période',
    labelDol:'dol',
    choicePourcentage:'Pourcentage',
    choiceDeliveryTpe:'Type de livraison',
    titleListStore:'Liste des restaurants',
    tabStore:'Restaurants',
    labelBusinessOff:'Business off',
    labelComission:'Commission',
    labelModeCommission:'Mode de commission',
    billingPeriod:'Période de Facturation',
    dollbarClientCode:'Code Client Dolibarr',
    restaurantView:'Vue restaurant',
    messageSuccessfullyDeclined:'Décliné avec succès',
    messageSuccessfullyApproved:'Approuvé avec succès',
    textDecline:'Décliner',
    textApprove:'Approuver',
    messageSuccesfullySendNotification:'Envoi de notification avec succès',
    messageSuccesfullyAddWallet:'Ajout dans le portefeuille avec succès',
    messageSuccesfullySentNotification:'Envoi de notification avec succès',
    textAddWallet:'Ajout de portefeuille',
    textNotification:'Notification',
    textCurrentWalletAmount:'Montant courant du portefeuille :',
    labelTotalWalletAmount:'Total montant portefeuille',
    labelBlocked:'Bloqué',
    labelFrom:'Provenance',
    labelCurrency:'Devise',
    labelWalletAmount:'Montant portefeuille',
    labelAddCut:'Add cut',
    labelEmailUser:"Email de l'utilisateur",
    labelDeliveryMen:"Email du livreur",
    labelEmailStore:"Email du restaurant",
    labelWalletDescription:'Description portefeuille',
    titleIncidentNumber:'Incident #',
    textSelectMe:'sélectionnez-moi',
    textAssign:'Assigner',
    messageAssignSuccessful:'Assignation réussie',
    labelDisponibility:'Disponibilité',
    labelNumberAssigned:'Nbr assignées',
    titleDashboard:'Dashboard',
    titleOrders:'Commandes',
    titleDeliveries:'Livraisons',
    titleHistory:'Historique',
    titleIncidents:'Incidents',
    titleUsers:'Utilisateurs',
    titleDeliverymen:'Livreurs',
    titleWalletHistory:'Portefeuilles',
    titleStore:'Restaurants',
    textDialogConfirmCancelFirstLine:'Êtes-vous certain de vouloir refuser cette commande ?',
    textDialogConfirmCancelSecondLine:'Cette action est irréversible',
    messageSuccesfullyCancel:'Annulé avec succès',
    textConfirm:'Confirmer',
    textTotalTTC:'TOTAL TTC:',
    textInVoiceOrderId:'Facture #',
    textTotalDeliveryTTC:'Frais de Livraison TTC',
    textTGC:'TGC',
    textAmountArticleTTC:'Montant des articles TTC',
    textPromotions:'Promotions',
    textDiscount:'Remises',
    textDeliveryMenProfit:'Gains du livreur',
    textCancelReason:"raison de l'annulation :",
    textDeliveryAdress:'Adresse de livraison',
    textSelectADelivery:'Sélectionner une livraison',
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    messageSuccessfulIsOnline:'Modification réussi',
};
