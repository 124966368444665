import { Fragment, useContext } from 'react';
import { useState } from 'react';
import DeliveryManEdit from './DeliveryManEdit';
import DeliveryManShow from './DeliveryManShow';
import Switch from '@material-ui/core/Switch';
import { List, TextField, SelectInput, TextInput, FunctionField, DataProviderContext, useNotify, useRefresh } from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import './deliveryMan.css';

const DeliveryManList = props => {
    const notify = useNotify();
    const refresh = useRefresh();

    const [tab, setTab] = useState(1);
    const t = props.t;
    const filterDefaultValues = {
        search_field: 'first_name',
        search_value: ''
    };
    const dataProvider = useContext(DataProviderContext);

    const handleChange = async (isActive, isOnline, id, server_token) => {
        const { data } = await dataProvider.customApiCall('deliverymen', {
            action: 'changeProviderStatus',
            provider_id: id,
            is_active_for_job: isActive,
            is_online: isOnline,
            server_token: server_token
        });
        if(data.success===true){
            notify(t('messageSuccessfulIsOnline'))
            refresh()
        }
    };

    const filters = [
        <SelectInput
            source='search_field'
            choices={[
                { id: 'first_name', name: t('labelFirstname') },
                { id: 'email', name: t('labelEmail') },
                { id: 'phone', name: t('labelPhone') },
                { id: 'unique_id', name: t('labelId') },
                { id: 'country_details.country_name', name: t('labelCountry') },
                { id: 'city', name: t('labelCity') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <TextInput source='search_value' label={t('labelSearch')} alwaysOn resettable />
    ];

    return (
        <div className='containerAlldeliveryMenListContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('deliverymanTitle')}</div>
                <List
                    {...props}
                    filters={filters}
                    title={t('deliverymanTitle')}
                    bulkActionButtons={false}
                    filterDefaultValues={filterDefaultValues}
                    filter={{ provider_page_type: tab }}
                >
                    <Fragment>
                        <Tabs
                            fullwidth='true'
                            value={tab}
                            indicatorColor='primary'
                            onChange={(event, value) => {
                                setTab(value);
                            }}
                        >
                            <Tab key={1} label={t('labelOnline')} value={1} />
                            <Tab key={2} label={t('labelApproved')} value={2} />
                            <Tab key={3} label={t('labelUnapprouved')} value={3} />
                        </Tabs>
                        <Divider />
                        <EditableDatagrid
                            undoable
                            editForm={<DeliveryManEdit />}
                            expand={<DeliveryManShow />}
                            rowClick='expand'
                            noDelete
                        >
                            <TextField source='unique_id' label={t('labelId')} sortable={false} />
                            <TextField source='first_name' sortable={false} label={t('labelFirstname')} />
                            <TextField source='last_name' sortable={false} label={t('labelLastname')} />
                            <TextField source='email' sortable={false} label={t('labelEmail')} />
                            <FunctionField
                                label={t('labelPhone')}
                                render={record => `${record.country_phone_code} ${record.phone}`}
                            />
                            <TextField
                                source='country_details.country_name'
                                label={t('labelCountry')}
                                sortable={false}
                            />
                            <TextField source='city_details.city_name' label={t('labelCity')} sortable={false} />
                            <TextField source='device_type' label={t('labelDeviceType')} sortable={false} />
                            <TextField source='app_version' label={t('labelAppVersion')} sortable={false} />
                            <FunctionField
                                label='En ligne'
                                render={record => {
                                    return (
                                        <Switch
                                            checked={record.is_online}
                                            onChange={() =>
                                                handleChange(
                                                    record.is_active_for_job,
                                                    !record.is_online,
                                                    record._id,
                                                    record.server_token
                                                )
                                            }
                                            color='primary'
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    );
                                }}
                            />
                            <FunctionField
                                label='Actif'
                                render={record => {
                                    return (
                                        <Switch
                                            checked={record.is_active_for_job}
                                            onChange={() =>
                                                handleChange(
                                                    !record.is_active_for_job,
                                                    record.is_online,
                                                    record._id,
                                                    record.server_token
                                                )
                                            }
                                            color='primary'
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    );
                                }}
                            />
                            {/* <BooleanField
              label="Approuvé"
              source="is_approved"
              sortable={false}
            />
            <BooleanField label="Online" source="is_online" sortable={false} /> */}
                            {/* <BooleanField
              label="Actif"
              source="is_active_for_job"
              sortable={false}
            /> */}
                        </EditableDatagrid>
                    </Fragment>
                </List>
            </div>
        </div>
    );
};

export default withTranslation()(DeliveryManList);
