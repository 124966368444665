import {
  AttachMoney,
  HowToReg,
  Cancel,
  Notifications,
  TwoWheeler,
} from "@material-ui/icons";
import { useState } from "react";
import { Dialog } from "@material-ui/core";

import ButtonCallApi from "../../components/ButtonCallApi";
import ButtonCallApiInput from "../../components/ButtonCallApiInput";
import ButtonSimple from "../../components/ButtonSimple";
import ToolbarCustom from "../../components/ToolBarCustom";
import DeliveryManVehicules from "./DeliveryManVehicles";

const UserShow = ({ record }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <ToolbarCustom className="toolbarCustom">
      <ButtonCallApi
        action="approveDecline"
        resource="deliverymen"
        text={record.is_approved ? "Decline" : "Approve"}
        otherParameters={record.is_approved ? 2 : 3}
        successMessage={
          record.is_approved ? "Successfully declined" : "Successfully approved"
        }
        Icon={record.is_approved ? Cancel : HowToReg}
      />
      <ButtonCallApiInput
        resource="deliverymen"
        successMessage="Successfull Add Wallet"
        action="addWallet"
        text="Add Wallet"
        Icon={AttachMoney}
      />
      <ButtonCallApiInput
        action="sendNotif"
        resource="deliverymen"
        successMessage="Successfull sent notification"
        text="send notif"
        Icon={Notifications}
      />
      <ButtonSimple
        action={() => {
          setOpenDialog(true);
        }}
        text="Vehicules"
        Icon={TwoWheeler}
      />
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        maxWidth={false}
      >
        <DeliveryManVehicules
          deliveryManId={record._id}
          closeDialog={() => {
            setOpenDialog(false);
          }}
        />
      </Dialog>
    </ToolbarCustom>
  );
};

export default UserShow;
