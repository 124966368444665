import { useRecordContext } from 'react-admin';
import { FlashOn, AccessTime } from '@material-ui/icons';
import { useState } from 'react';
import { DateField } from 'react-admin';

const style = {
    display: 'flex',
    justifyContent: 'center'
};

const FieldType = ({ source }) => {
    const [showTime, setShowTime] = useState(false);
    const record = useRecordContext();
    if (!record) return null;
    const is_schedule_order = record.order_detail ? record.order_detail.is_schedule_order : record.is_schedule_order;
    if (is_schedule_order === false)
        return (
            <div style={style}>
                <FlashOn />
            </div>
        );
    return (
        <div
            style={style}
            onClick={e => {
                e.stopPropagation();
                setShowTime(!showTime);
            }}
        >
            {!!showTime && record.order_detail !== undefined ? (
                <DateField
                    label='Heure de création'
                    source='order_detail.schedule_order_start_at'
                    showTime
                    sortable={false}
                    options={{
                        timeZone: record.timezone,
                        day: '2-digit',
                        month: 'long',
                        hour: '2-digit',
                        minute: '2-digit'
                    }}
                />
            ) : !!showTime ? (
                <DateField
                    label='Heure de création'
                    source='schedule_order_start_at'
                    showTime
                    sortable={false}
                    options={{
                        timeZone: record.timezone,
                        day: '2-digit',
                        month: 'long',
                        hour: '2-digit',
                        minute: '2-digit'
                    }}
                />
            ) : (
                <AccessTime />
            )}
        </div>
    );
};

export default FieldType;
