import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { request_status, search_value, search_field } = params.filter;

        return httpClient(`${apiUrl}/api/admin/delivery_list_search_sort`, {
            method: 'POST',
            body: JSON.stringify({
                page: page,
                payment_status: 'all',
                request_status: request_status || '',
                search_field: search_field || 'user_detail.first_name',
                search_value: search_value || ''
            })
        }).then(async ({ json }) => {
            const data = json.requests
                ? json.requests.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : null;
            return {
                data,
                total: json.pages * 10
            };
        });
    },
    getOne: (resource, params) => {
        return new Promise((resolve, reject) => {
            resolve('foo');
        }).then(res => {
            return { data: { id: 'toto' } };
        });
    },
    cancel: (resource, params) => {
        return httpClient(`${apiUrl}/api/store/cancel_request`, {
            method: 'POST',
            body: JSON.stringify({
                from_admin: true,
                request_id: params.record._id,
                provider_id: params.record.provider_id,
                store_id: params.record.request_type_id
                // Strangly in the prod tests the store_id payload corresponds to request_type_id
            })
        }).then(({ json }) => {
            return {
                data: json
            };
        });
    },
    deliveryManNearby: (resource, params) => {
        return httpClient(`${apiUrl}/admin/nearby_providers_list`, {
            method: 'POST',
            body: JSON.stringify({
                order_id: params.record.orders.order_id
            })
        }).then(({ json }) => {
            return {
                data: json
            };
        });
    },
    assignDM: (resource, params) => {
        // api expects an array
        params.otherParameters.orders = [params.otherParameters.orders];

        return httpClient(`${apiUrl}/admin/assign_provider`, {
            method: 'POST',
            body: JSON.stringify({
                provider_id: params.record._id,
                request: params.otherParameters
            })
        }).then(({ json }) => {
            return {
                data: json
            };
        });
    }
};

export const callApiIndicators = async () => {
    let resFinal;
    const req = await httpClient(`${apiUrl}/admin/get_providers_indicators`, {
        method: 'GET'
    })
        .then(res => {
            if (res.json.success === true) {
                resFinal = res.json.indicators;
            }

            //   const data = res.json.products.length > 0 ? res.json.products[0].items.image_url[0] : [];
        })
        .catch(e => {
            console.log(e, 'error return');
        });

    return resFinal;
};
