import { TextField, TextInput, BooleanInput, SelectInput } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import { withTranslation } from 'react-i18next';

const StoreEdit = props => {
    const t = props.t
    return (
        <RowForm {...props}>
            <TextField source='unique_id' label={t('labelId')} disabled />
            <TextInput source='name' style={{ width: '10rem' }} label={t('labelLastname')} />
            <TextInput source='email' />
            <TextInput source='country_phone_code' label={t('labelCountry')} />
            <TextInput source='phone' label={t('textPhone')} />
            <TextField source='city_details.city_name' label={t('labelTown')} disabled />
            <TextField source='address' label={t('labelAdress')} disabled />
            {/* <BooleanInput source='is_approved' label='Approuvé' /> */}
            <BooleanInput source='is_visible' label={t('labelVisible')} />
            <BooleanInput source='is_business' label={t('labelActive')} />
            <TextInput source='admin_profit_value_on_store' label={t('labelProfit')} style={{ width: '4rem' }} />
            <SelectInput
                source='admin_profit_mode_on_store'
                choices={[
                    { id: 1, name: 'percentage' },
                    { id: 2, name: 'absolute price per order' },
                    { id: 3, name: 'absolute price per item' }
                ]}
                label={t('labelAdminProfitModeOnStore')}
            />
            ,
            <TextInput source='billing_period' label={t('labelPeriod')} />
            <TextInput source='dol_code_client' label={t('labelDol')} />
        </RowForm>
    );
};

export default withTranslation()(StoreEdit);
