import { MultiLevelMenu, MenuItem } from '@react-admin/ra-navigation';
import { usePermissions } from 'react-admin';

// Icons
import RestaurantRoundedIcon from '@material-ui/icons/Restaurant';
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import UserIcon from '@material-ui/icons/People';
import AddTask from '@material-ui/icons/AddBox';
import Dashboard from '@material-ui/icons/Dashboard';
import CreditCard from '@material-ui/icons/CreditCard';
import logoNeedeat from '../assets/logoNeedeat.png';
import styles from './Menu.css';
import { withTranslation } from 'react-i18next';
import { checkPerm } from '../utils';

const Menu = ({ t }) => {
    const { permissions } = usePermissions();

    return (
        <div className='containerAllMenu'>
            <MultiLevelMenu initialOpen={true}>
                <div className='userNameContainer'>welcome, {JSON.parse(localStorage.getItem('auth')).username}</div>
                {checkPerm('dashboard', permissions) && (
                    <MenuItem name='dashboard' to='/' exact label={t('titleDashboard')} icon={<Dashboard />} />
                )}

                {checkPerm('ordersMenu', permissions) && (
                    <MenuItem name='orders' to='/orders' label={t('titleOrders')} icon={<AddTask />}>
                        {checkPerm('orders', permissions) && (
                            <MenuItem name='orders' to='/orders' label={t('titleOrders')}></MenuItem>
                        )}
                        {checkPerm('deliveries', permissions) && (
                            <MenuItem name='deliveries' to='/deliveries' label={t('titleDeliveries')}></MenuItem>
                        )}
                        {checkPerm('history', permissions) && (
                            <MenuItem name='history' to='/history' label={t('titleHistory')}></MenuItem>
                        )}
                        {checkPerm('incidents', permissions) && (
                            <MenuItem name='incidents' to='/incidents' label={t('titleIncidents')}></MenuItem>
                        )}
                    </MenuItem>
                )}

                {checkPerm('users', permissions) && (
                    <MenuItem name='users' to='/users' label={t('titleUsers')} icon={<UserIcon />} />
                )}
                {checkPerm('stores', permissions) && (
                    <MenuItem name='stores' to='/stores' label={t('titleStore')} icon={<RestaurantRoundedIcon />} />
                )}
                {checkPerm('deliverymen', permissions) && (
                    <MenuItem
                        name='Deliveryman'
                        to={'/deliverymen'}
                        label={t('titleDeliverymen')}
                        icon={<DirectionsBike />}
                    ></MenuItem>
                )}
                {checkPerm('wallethistory', permissions) && (
                    <MenuItem
                        name='WalletHistoire'
                        to={'/wallethistory'}
                        label={t('titleWalletHistory')}
                        icon={<CreditCard />}
                    ></MenuItem>
                )}
                <div className='imgLogoMenuContainer'>
                    <img className='imgLogoMenu' src={logoNeedeat} />
                </div>
            </MultiLevelMenu>
        </div>
    );
};

export default withTranslation()(Menu);
