import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;

const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { search_value, search_field, provider_page_type } = params.filter;

        return httpClient(`${apiUrl}/api/provider/provider_list_search_sort`, {
            method: 'POST',
            body: JSON.stringify({
                search_field,
                search_value: search_value || '',
                sort_field: 'unique_id',
                sort_provider: 1,
                number_of_rec: perPage,
                page,
                provider_page_type
            })
        }).then(({ json }) => {
            const data = json.providers
                ? json.providers.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * perPage
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(apiUrl + '/admin/get_provider_detail', {
            method: 'POST',
            body: JSON.stringify({
                provider_id: params.id
            })
        }).then(({ json }) => {
            json.provider.id = json.provider._id;
            return {
                data: json.provider
            };
        });
    },
    update: (resource, params) => {
        if (!params.data.admin_vehicle_id) {
            params.data.provider_id = params.id;
            return httpClient(apiUrl + '/admin/update_provider', {
                method: 'POST',
                body: JSON.stringify(params.data)
            }).then(({ json }) => {
                json.provider.id = params.id;
                return { data: json.provider };
            });
        } else {
            // params.data.provider_id = params.id;
            return httpClient(apiUrl + '/admin/provider_vehicle_update', {
                method: 'POST',
                body: JSON.stringify(params.data)
            }).then(({ json }) => {
                json.provider_vehicle.id = json.provider_vehicle._id;
                return { data: json.provider_vehicle };
            });
        }
    },
    approveDecline: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/provider_approve_decline', {
            method: 'POST',
            body: JSON.stringify({
                provider_id: record.id,
                provider_page_type: otherParameters
                // 2 unapprove / 3 approve
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    addWallet: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/add_wallet', {
            method: 'POST',
            body: JSON.stringify({
                type: 8,
                provider_id: record._id,
                wallet: otherParameters.input
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    sendNotif: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/send_notification', {
            method: 'POST',
            body: JSON.stringify({
                type: 8,
                provider_id: record._id,
                content: otherParameters.input
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    getDeliveryManVehicules: (resource, params) => {
        return httpClient(apiUrl + '/admin/provider_vehicle_list', {
            method: 'POST',
            body: JSON.stringify({
                provider_id: params.deliveryManId
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    changeProviderStatus: (resource, params) => {
        return httpClient(apiUrl + '/api/provider/change_status', {
            method: 'POST',
            body: JSON.stringify({
                provider_id: params.provider_id,
                is_active_for_job:params.is_active_for_job,
                is_online:params.is_online,
                server_token: params.server_token,
            })
        }).then(({ json }) => {
            if(json.success === true){
                return{data : json}
            }
        });
    },

};
