import React from 'react';
import keyBy from 'lodash/keyBy';
import { Datagrid, TextField, ListContextProvider, FunctionField } from 'react-admin';
import ButtonCallApi from './ButtonCallApi';
import './DMSelector.css';
import { CheckCircle, Cancel } from '@material-ui/icons';

const DMSelector = ({ data, setDialogData, currRequest, t }) => {
    data.forEach(el => {
        el.id = el._id;
    });

    return (
        <div className='DMSelectorContainer'>
            <ListContextProvider
                value={{
                    data: keyBy(data, 'id'),
                    ids: data.map(({ id }) => id),
                    total: data.length,
                    page: 1,
                    perPage: 10,
                    currentSort: { field: 'id', order: 'ASC' }
                }}
            >
                <Datagrid rowClick=''>
                    <TextField label={t('labelFirstname')} source='first_name' />
                    <TextField label={t('labelLastname')} source='last_name' />
                    <TextField label={t('labelPhone2')} source='phone' />
                    <FunctionField
                        label={t('labelDisponibility')}
                        render={rec => (
                            <>
                                {rec.current_requests > 0 ? (
                                    <div className='noDisponibilityIcon'>
                                        <Cancel />
                                    </div>
                                ) : (
                                    <div className='yesDisponibilityIcon'>
                                        <CheckCircle />
                                    </div>
                                )}
                            </>
                        )}
                    />
                    <FunctionField
                        label={t('textSelectMe')}
                        render={rec => (
                            <ButtonCallApi
                                resource='deliveries'
                                text={t('textAssign')}
                                successMessage={t('messageAssignSuccessful')}
                                action='assignDM'
                                successAction={(data, record) => {
                                    setDialogData(null);
                                }}
                                failAction={(data, record) => {
                                    setDialogData(null);
                                }}
                                otherParameters={currRequest}
                                Icon={CheckCircle}
                            />
                        )}
                    />
                    <FunctionField label={t('labelNumberAssigned')} render={rec => <>{rec.current_requests}</>} />
                </Datagrid>
            </ListContextProvider>
        </div>
    );
};

export default DMSelector;
