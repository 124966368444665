import { Fragment } from 'react';
import { useState } from 'react';
import UserEdit from './UserEdit';
import UserShow from './UserShow';

import { List, TextField, SelectInput, TextInput, FunctionField } from 'react-admin';
import FieldCustomWidth from '../../components/FieldCustomWidth';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import './user.css';

const userFilters = [
    <SelectInput
        source='search_field'
        choices={[
            { id: 'first_name', name: 'Name' },
            { id: 'email', name: 'Email' },
            { id: 'phone', name: 'Phone' },
            { id: 'unique_id', name: 'ID' },
            { id: 'city', name: 'City' }
        ]}
        alwaysOn
        allowEmpty={false}
    />,
    <TextInput source='search_value' label='search' alwaysOn resettable />
];

const UserList = props => {
    const t = props.t;
    const [tab, setTab] = useState(1);

    const filterDefaultValues = {
        search_field: 'first_name',
        search_value: ''
    };

    return (
        <div className='containerAllUserListContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('userListTitle')}</div>
                <List
                    {...props}
                    filters={userFilters}
                    title={t('userListTitle')}
                    bulkActionButtons={false}
                    filterDefaultValues={filterDefaultValues}
                    filter={{ user_page_type: tab }}
                >
                    <Fragment>
                        <Tabs
                            fullwidth='true'
                            value={tab}
                            indicatorColor='primary'
                            onChange={(event, value) => {
                                setTab(value);
                            }}
                        >
                            <Tab key={1} label={t('labelUser')} value={1} />
                            <Tab key={2} label={t('labelBlocked')} value={2} />
                        </Tabs>
                        <Divider />

                        <EditableDatagrid
                            undoable
                            editForm={<UserEdit />}
                            expand={<UserShow />}
                            rowClick='expand'
                            noDelete
                        >
                            <TextField source='unique_id' label={t('labelId')} sortable={false} />
                            <FunctionField
                                label='Nom Prénom'
                                render={record => `${record.first_name} ${record.last_name}`}
                            />
                            <TextField source='email' sortable={false} label={t('labelEmail')} />
                            <FunctionField
                                label={t('labelPhone2')}
                                render={record => `${record.country_phone_code} ${record.phone}`}
                            />
                            <FieldCustomWidth
                                source='address'
                                label={t('labelAdress')}
                                sortable={false}
                                width='150px'
                            />
                            <TextField source='city' label={t('labelCity')} sortable={false} />
                            <FunctionField
                                render={record => `${record.wallet} ${record.wallet_currency_code}`}
                                label={t('labelWallet')}
                            />
                            <TextField source='device_type' label={t('labelDeviceType')} sortable={false} />
                            <TextField source='app_version' label={t('labelAppVersion')} sortable={false} />
                        </EditableDatagrid>
                    </Fragment>
                </List>
            </div>
        </div>
    );
};

export default withTranslation()(UserList);
