import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { search_field, search_value, order_status, order_type } = params.filter;

        return httpClient(`${apiUrl}/admin/orders_list`, {
            method: 'POST',
            body: JSON.stringify({
                page: page,
                order_status,
                order_type: order_type,
                payment_status: 'all',
                pickup_type: 'both',
                search_field: search_field || 'store_detail.name',
                search_value: search_value || '',
                created_by: 'both'
            })
        }).then(({ json }) => {
            const data = json.orders
                ? json.orders.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * 10
            };
        });
    },
    // DOES NOT WORK. API NEEDS STORE SERVER TOKEN
    cancelOrder: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/api/store/store_cancel_or_reject_order', {
            method: 'POST',
            body: JSON.stringify({
                cancel_reason: 'admin cancel',
                order_id: record._id,
                order_status: 104,
                server_token: record.store_detail.server_token,
                store_id: record.store_id
            })
        }).then(({ json }) => {
            return { data: json };
        });
    }
};
