import { TextInput, TextField } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import { withTranslation } from 'react-i18next';

const DeliveryManEdit = props => {
    const t = props.t;
    return (
        <RowForm {...props}>
            <TextField source='unique_id' label={t('labelId')} sortable={false} />
            <TextInput source='first_name' label={t('labelFirstname')} />
            <TextInput source='last_name' label={t('labelLastname')} />
            <TextInput source='email' label={t('labelEmail')} />
            <TextInput source='country_phone_code' label={t('labelPhoneCode')} />
            <TextInput source='phone' label={t('labelPhone')} />
            <TextField source='city_details.city_name' label={t('labelTown')} sortable={false} />
            <TextField source='device_type' label={t('labelDeviceType')} sortable={false} />
            <TextField source='app_version' label={t('labelAppVersion')} sortable={false} />
            {/* <BooleanInput label="" source="is_approved" />
      <BooleanInput label="" source="is_online" />
      <BooleanInput label="" source="is_active_for_job" /> */}
        </RowForm>
    );
};

export default withTranslation()(DeliveryManEdit);
