import {
    //   FIELDS
    TextField,
    //   INPUTS
    SelectInput,
    TextInput,
    //   MISC
    SimpleForm,
    required,
    Toolbar,
    SaveButton
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { withTranslation } from 'react-i18next';

const CustomToolbar = props => (
    <div className='saveButton'>
        <Toolbar {...props}>
            <SaveButton icon={<></>} label={'enregistrer'} />
        </Toolbar>
    </div>
);

const MyChildrenContainerComponent = props => {
    const t = props.t;
    const validateNotEmpty = [required(t('fieldMandatory'))];
    return (
        <div className='incidentContainer'>
            <div className='incidentORderId'>
                <span>{t('labelOderId')}</span>
                <TextField validate={validateNotEmpty} source='order_unique_id' label={t('labelOderId')} />
            </div>

            <div className='rowFlex'>
                <div className='incidentBlock'>
                    <div className='rowInput'>
                        <TextInput className='inputIncident' validate={validateNotEmpty} source='issue' multiline />
                    </div>

                    <div className='rowInput'>
                        <TextInput
                            className='inputIncident'
                            validate={validateNotEmpty}
                            source='resolution'
                            multiline
                        />
                    </div>
                    <div className='rowInput'>
                        <SelectInput
                            className='inputIncident'
                            validate={validateNotEmpty}
                            source='accountable'
                            choices={[
                                { id: '0', name: t('labelRestaurant') },
                                { id: '1', name: t('labelUser') },
                                { id: '2', name: t('labelProvider') }
                            ]}
                        />{' '}
                    </div>

                    <div className='rowInput'>
                        <SelectInput
                            className='inputIncident'
                            validate={validateNotEmpty}
                            source='status'
                            choices={[
                                { id: '0', name: t('labelPending') },
                                { id: '1', name: t('labelIncidentCompleted') }
                            ]}
                        />
                    </div>
                </div>
                <div className='incidentBlock'>
                    <div className='rowInput'>
                        
                        <TextInput label={t('labelAddCutToProvider')} placeholder='xxx' className='inputIncident' source='add_cut_to_provider' />
                    </div>

                    <div className='rowInput'>
                        <TextInput label={t('labelAddCutToUser')} className='inputIncident' source='add_cut_to_user' />
                    </div>

                    <div className='rowInput'>
                        <TextInput label={t('labelAddCutToStore')} className='inputIncident' source='add_cut_to_store' />
                    </div>
                </div>
            </div>
        </div>
    );
};

const IncidentCreate = props => {
    const t = props.t;
    const transform = data => {
        data.add_cut_to_provider = data.add_cut_to_provider || 0
        data.add_cut_to_store = data.add_cut_to_store || 0
        data.add_cut_to_user = data.add_cut_to_user || 0
        return data;
    };
    if (!props.location.state) return null;
    return (
        <CreateDialog
            className='createDialogAllContainer'
            transform={transform}
            {...props}
            title={<span>{t('titleCreateIncident')}</span>}
            mutationMode='pessimistic'
            successMessage={t('messageSuccesIncidentCreate')}
            {...props}
        >
            <SimpleForm toolbar={<CustomToolbar />}>
                <MyChildrenContainerComponent {...props} />
            </SimpleForm>
        </CreateDialog>
    );
};

export default withTranslation()(IncidentCreate);
