import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { user_page_type, search_value, search_field, store_page_type } = params.filter;

        return httpClient(`${apiUrl}/admin/store_list_search_sort`, {
            method: 'POST',
            body: JSON.stringify({
                number_of_rec: perPage,
                page,
                search_field: search_field || 'name',
                search_value: search_value || '',
                sort_field: 'unique_id',
                sort_store: -1,
                store_page_type
            })
        }).then(({ json }) => {
            const data = json.stores
                ? json.stores.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * perPage
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(apiUrl + '/admin/get_store_data', {
            method: 'POST',
            body: JSON.stringify({
                store_id: params.id
            })
        }).then(({ json }) => {
            json.store_detail.id = json.store_detail._id;
            return {
                data: json.store_detail
            };
        });
    },

    update: (resource, params) => {
        params.data.store_id = params.id;
        return httpClient(apiUrl + '/admin/update_store', {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => {
            json.store.id = params.id;
            return { data: json.store };
        });
    },
    approveDecline: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/approve_decline_store', {
            method: 'POST',
            body: JSON.stringify({
                store_id: record.id,
                store_page_type: otherParameters
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    addWallet: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/add_wallet', {
            method: 'POST',
            body: JSON.stringify({
                type: 2,
                store_id: record._id,
                wallet: otherParameters.input
            })
        }).then(({ json }) => {
            return { data: json };
        });
    },
    sendNotif: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/admin/send_notification', {
            method: 'POST',
            body: JSON.stringify({
                type: 2,
                store_id: record._id,
                content: otherParameters.input
            })
        }).then(({ json }) => {
            return { data: json };
        });
    }
};
