import dataProviderUsers from './dataProviderUsers';
import dataProviderStores from './dataProviderStores';
import dataProviderDeliveryMen from './dataProviderDeliveryMen';
import dataProviderOrders from './dataProviderOrders';
import dataProviderDeliveries from './dataProviderDeliveries';
import dataProviderHistory from './dataProviderHistory';
import dataProviderWalletHistory from './dataProviderWalletHistory';
import dataProviderIncidents from './dataProviderIncidents';

const allProviders = {
    users: dataProviderUsers,
    stores: dataProviderStores,
    deliverymen: dataProviderDeliveryMen,
    orders: dataProviderOrders,
    deliveries: dataProviderDeliveries,
    history: dataProviderHistory,
    wallethistory: dataProviderWalletHistory,
    incidents: dataProviderIncidents
};

export default {
    getList: (resource, params) => allProviders[resource].getList(resource, params),
    getOne: (resource, params) => allProviders[resource].getOne(resource, params),
    update: (resource, params) => allProviders[resource].update(resource, params),
    create: (resource, params) => allProviders[resource].create(resource, params),
    customApiCall: (resource, params) => allProviders[resource][params.action](resource, params)
};
