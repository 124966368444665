import * as React from 'react';
import { Admin, Resource } from 'react-admin';

// Resources
import orders from './resources/orders';
import deliveries from './resources/deliveries';
import users from './resources/users';
import stores from './resources/stores';
import deliverymen from './resources/deliverymen';
import history from './resources/history';
import walletHistory from './resources/walletHistory';
import incidents from './resources/incidents';
import { I18nextProvider } from 'react-i18next';

// Components
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';

// Provider
import dataProvider from './data/dataProvider';
import authProvider from './data/authProvider';
import i18n from './translation';
import { checkPerm } from './utils';

const App = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Admin dashboard={Dashboard} dataProvider={dataProvider} layout={Layout} authProvider={authProvider}>
                {permissions => {
                    return [
                        checkPerm('orders', permissions) ? <Resource name='orders' {...orders} /> : null,
                        checkPerm('deliveries', permissions) ? <Resource name='deliveries' {...deliveries} /> : null,
                        checkPerm('users', permissions) ? <Resource name='users' {...users} /> : null,
                        checkPerm('stores', permissions) ? <Resource name='stores' {...stores} /> : null,
                        checkPerm('deliverymen', permissions) ? <Resource name='deliverymen' {...deliverymen} /> : null,
                        checkPerm('deliverymenVehicules', permissions) ? (
                            <Resource name='deliverymenVehicules' />
                        ) : null,
                        checkPerm('history', permissions) ? <Resource name='history' {...history} /> : null,
                        checkPerm('wallethistory', permissions) ? (
                            <Resource name='wallethistory' {...walletHistory} />
                        ) : null,
                        checkPerm('incidents', permissions) ? <Resource name='incidents' {...incidents} /> : null
                    ];
                }}
            </Admin>
        </I18nextProvider>
    );
};
export default App;
