// in src/Dashboard.js
import * as React from 'react';
import ImgNeedeat from '../assets/img1.jpg';
import './Dashboard.css';
import { usePermissions } from 'react-admin';
import { checkPerm } from '../utils';

export default () => {
    const { permissions } = usePermissions();
    if (checkPerm('dashboard', permissions)) {
        return (
            <div className='dashboardContainer'>
                <iframe
                    className='iframeDashboard'
                    title='Planification livreurs'
                    src='https://app.powerbi.com/reportEmbed?reportId=dcee390a-53b1-476a-9d9b-5cb96b96404a&autoAuth=true&ctid=b7157343-f949-4131-8651-2331f9ebb614'
                    frameborder='0'
                    allowFullScreen='true'
                ></iframe>
            </div>
        );
    } else {
        return (
            <div>
                <img src={ImgNeedeat} width='100%'></img>
            </div>
        );
    }
};
