import { Fragment } from 'react';
import { useState } from 'react';
import { List, TextField, FunctionField, SelectInput, TextInput } from 'react-admin';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import StoreEdit from './StoreEdit';
import StoreShow from './StoreShow';
import { admin_profit_mode_on_store } from '../../data/constants';
import './Store.css';
import { withTranslation } from 'react-i18next';

export const StoreList = props => {
    const t = props.t
    const [tab, setTab] = useState(1);

    const storeFilters = [
        <SelectInput
            source='search_field'
            choices={[
                { id: 'name', name: t('labelLastname') },
                { id: 'unique_id', name: t('labelId') },
                { id: 'email', name: t('labelEmail') },
                { id: 'phone', name: t('labelPhone') },
                { id: 'country_details.country_name', name: t('labelCountry') },
                { id: 'city_details.city_name', name: t('labelCity') },
                { id: 'delivery_details.delivery_name', name: t('choiceDeliveryTpe') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <TextInput source='search_value' label={t('labelSearch')} alwaysOn resettable />
    ];
    const filterDefaultValues = {
        search_field: 'name',
        search_value: ''
    };

    return (
        <div className='containerAllStoreListContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('titleListStore')}</div>
                <List
                    {...props}
                    empty={false}
                    filters={storeFilters}
                    title={t('titleListStore')}
                    bulkActionButtons={false}
                    filterDefaultValues={filterDefaultValues}
                    filter={{ store_page_type: tab }}
                >
                    <Fragment>
                        <Tabs
                            fullwidth='true'
                            value={tab}
                            indicatorColor='primary'
                            onChange={(event, value) => {
                                setTab(value);
                            }}
                        >
                            <Tab key={1} label={t('tabStore')} value={1} />
                            <Tab key={2} label={t('labelUnapprouved')} value={2} />
                            <Tab key={3} label={t('labelBusinessOff')} value={3} />
                        </Tabs>
                        <Divider />

                        <EditableDatagrid
                            undoable
                            editForm={<StoreEdit />}
                            expand={<StoreShow />}
                            rowClick='expand'
                            noDelete
                        >
                            <TextField label={t('labelId')} source='unique_id' sortable={false} />
                            <TextField source='name' label={t('labelLastname')} />
                            <TextField source='email' label={t('labelEmail')} />
                            <TextField label={t('labelCountry')} source='country_details.country_name' sortable={false} />
                            <FunctionField
                                label={t('labelPhone2')}
                                render={record => `${record.country_phone_code} ${record.phone}`}
                            />
                            <TextField label={t('labelCity')} source='city_details.city_name' sortable={false} />
                            <FunctionField
                                label={t('labelAdress')}
                                sortable={false}
                                render={record => <div className='adressStoreListTextField'>{record.address}</div>}
                            />
                            {/* <FieldCustomWidth source='address' sortable={false} label='Adresse' /> */}
                            <TextField label={t('labelDeviceType')} source='device_type' sortable={false} />
                            <TextField label={t('labelAppVersion')} source='app_version' sortable={false} />
                            <FunctionField
                                label={t('labelComission')}
                                render={record => `${record.admin_profit_value_on_store} %`}
                            />
                            <FunctionField
                                label={t('labelModeCommission')}
                                render={record => admin_profit_mode_on_store[record.admin_profit_mode_on_store]}
                            />
                            <TextField label={t('billingPeriod')} source='billing_period' sortable={false} />
                            <TextField label={t('dollbarClientCode')} source='dol_code_client' sortable={false} />
                        </EditableDatagrid>
                    </Fragment>
                </List>
            </div>
        </div>
    );
};

export default withTranslation()(StoreList);
