import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { search_field, search_value, start_date, end_date, user_type } = params.filter;

        return httpClient(`${apiUrl}/admin/get_wallet_detail`, {
            method: 'POST',
            body: JSON.stringify({
                start_date: start_date ? { formatted: start_date } : '',
                end_date: end_date ? { formatted: end_date } : '',
                page: page,
                search_field: search_field || 'wallet_description',
                search_value: search_value || '',
                user_type: user_type || '0',
                wallet_comment_id: 0
            })
        }).then(({ json }) => {
            const data = json.wallet
                ? json.wallet.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * 10
            };
        });
    }
};
