import { TextInput, TextField } from "react-admin";
import { RowForm } from "@react-admin/ra-editable-datagrid";

const UserEdit = (props) => {
  return (
    <RowForm {...props}>
      <TextField source="unique_id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextInput source="country_phone_code" label="Country" />
      <TextInput source="phone" />
      <TextField source="city" />
      <TextField source="wallet" />
      <TextField source="device_type" />
      <TextField source="app_version" />
    </RowForm>
  );
};

export default UserEdit;
