import { REACT_APP_API_URL } from '../config';

let firstVerification = true;

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${REACT_APP_API_URL}/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if (!auth.success) {
                    throw new Error('login fails');
                } else {
                    localStorage.setItem('auth', JSON.stringify(auth.admin_data));
                }
            })
            .catch(() => {
                throw new Error('Login Fails');
            });
    },
    checkAuth: () => {
        const LSContent = localStorage.getItem('auth');
        if (!LSContent) {
            return Promise.reject('Please Login');
        }
        const { server_token, _id } = JSON.parse(localStorage.getItem('auth'));
        if (server_token && firstVerification === false) {
            return Promise.resolve('Okay');
        }
        // If we are here, it means we just landed and we have to check the token that was stored in local storage
        const request = new Request(`${REACT_APP_API_URL}/admin/check_auth`, {
            method: 'POST',
            body: JSON.stringify({ admin_id: _id, admin_token: server_token }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(auth => {
                firstVerification = false;
                if (!auth.success) {
                    throw new Error('auth fails');
                }
                // replace the auth token with the new received data
                localStorage.setItem('auth', JSON.stringify(auth.admin));
            })
            .catch(() => {
                throw new Error('Auth Fail');
            });
    },
    getPermissions: () => {
        const role = JSON.parse(localStorage.getItem('auth'))?.urls;
        return role ? Promise.resolve(role.length > 0 ? role : 'admin') : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('permissions');
        localStorage.removeItem('auth');
        return Promise.resolve();
    }
};

export default authProvider;
