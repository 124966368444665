import moment from 'moment';

export const getDayFromNumber = (t, day) => {
    day = parseInt(day);
    switch (day) {
        case 0:
            return t('sunday');
            break;
        case 1:
            return t('monday');
            break;
        case 2:
            return t('tuesday');
            break;
        case 3:
            return t('wednesday');
            break;
        case 4:
            return t('thursday');
            break;
        case 5:
            return t('friday');
            break;
        case 6:
            return t('saturday');
            break;
        default:
            return t('sunday');
            break;
    }
};

export const getReadableDateFromTimezone = (t, date, timezone) => {
    let dayOfWeek = moment(
        new Date(date).toLocaleString('en-US', {
            timeZone: timezone
        })
    ).format('d');
    let dayString = getDayFromNumber(t, dayOfWeek);
    let dateFormatted = moment(
        new Date(date).toLocaleString('en-US', {
            timeZone: timezone
        })
    ).format('DD/MM/YYYY --- HH:mm:ss');

    return `${dayString} ${dateFormatted}`;
};

export const checkPerm = (resource, permissions) => {
    if (!resource || !permissions) {
        return false;
    }
    if (permissions === 'admin') {
        return true;
    }
    switch (resource) {
        case 'dashboard':
            return permissions.includes('/admin/dashboard');
        case 'orders':
            return permissions.includes('/admin/orders');
        case 'ordersMenu':
            return (
                permissions.includes('/admin/orders') ||
                permissions.includes('/admin/history') ||
                permissions.includes('/admin/deliveries') ||
                permissions.includes('/admin/incidents')
            );
        case 'deliveries':
            return permissions.includes('/admin/deliveries');
        case 'users':
            return permissions.includes('/admin/users');
        case 'stores':
            return permissions.includes('/admin/stores');
        case 'deliverymen':
            return permissions.includes('/admin/providers');
        case 'deliverymenVehicules':
            return permissions.includes('/admin/orders');
        case 'history':
            return permissions.includes('/admin/history');
        case 'wallethistory':
            return permissions.includes('/admin/wallet_detail');
        case 'incidents':
            return permissions.includes('/admin/incidents');
        default:
            return false;
    }
};
