import * as React from 'react';
import { useState, useContext } from 'react';
import './ButtonCallApi.css';

import { useRecordContext, DataProviderContext, useRefresh, useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';

const ButtonCallApi = ({
    resource,
    action,
    successAction,
    failAction,
    successMessage,
    text,
    otherParameters,
    Icon,
    noErrorNotif,
    recordProp = null
}) => {
    let record = useRecordContext();
    if (record === undefined) {
        record = recordProp;
    }
    const [loading, setLoading] = useState(false);
    const dataProvider = useContext(DataProviderContext);
    const [bubble, setBubble] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();

    const APICall = () => {
        setLoading(true);
        dataProvider
            .customApiCall(resource, {
                action,
                record,
                otherParameters
            })
            .then(({ data }) => {
                setLoading(false);
                if (!data.success) {
                    if (!noErrorNotif) {
                        notify(`error, code ${data.error_code}`, {
                            type: 'warning'
                            // duration: 5,
                        });
                    }
                    if (failAction) {
                        failAction(data, record);
                    }
                    refresh();
                } else {
                    if (successAction) {
                        successAction(data, record);
                    }
                    if (successMessage) notify(successMessage, { type: 'success', duration: 1 });
                    refresh();
                }
            })
            .catch(error => {
                refresh();
                setLoading(false);
                notify('there was an api error', { type: 'warning', duration: 500 });
                console.log('ERROR button', error);
            });
    };

    return (
        <div className='buttonCallApiContainer'>
            {loading ? (
                <CircularProgress size={'20px'} />
            ) : (
                <button
                    className='btn first'
                    onClick={e => {
                        e.stopPropagation();
                        APICall();
                    }}
                    onMouseEnter={() => {
                        setBubble(true);
                    }}
                    onMouseLeave={() => {
                        setBubble(false);
                    }}
                >
                    {!bubble ? <Icon /> : <div className='insideButton'> {text}</div>}
                </button>
            )}
        </div>
    );
};

export default ButtonCallApi;
