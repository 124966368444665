import {
    //   FIELDS
    TextField,
    FunctionField,
    //   MISC
    List,
    Datagrid,
    Pagination,
    TopToolbar,
    EditButton,
} from 'react-admin';
import IncidentCreate from './IncidentCreate';
import IncidentEdit from './IncidentEdit';
import FieldCustomWidth from '../../components/FieldCustomWidth';
import { withTranslation } from 'react-i18next';

const MyPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;

const MyListActions = props => <TopToolbar>{/* <CreateButton /> */}</TopToolbar>;

const IndicentList = props => {
    const t=props.t
    return (
        <div className='containerAllIncidentsListContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>Liste des incidents</div>
                <List
                    {...props}
                    title='Incidents'
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    actions={<MyListActions />}
                >
                    <Datagrid>
                        {/* <TextField source='id' label='Id' sortable={false} /> */}
                        <TextField source='unique_id' label={t('labelId')} sortable={false} />
                        <TextField source='order_unique_id' label={t('labelOderId')} sortable={false} />
                        <FieldCustomWidth source='issue' label={t('labelIssue')} width={'200px'} sortable={false} />
                        <FieldCustomWidth source='resolution' label={t('labelResolution')} width={'200px'} sortable={false} />
                        {/* <TextField source='added_cut_to_store' label='added_cut_to_store' sortable={false} />
                    <TextField source='added_cut_to_provider' label='added_cut_to_provider' sortable={false} />
                    <TextField source='added_cut_to_user' label='added_cut_to_user' sortable={false} />
                    <TextField source='accountable' label='accountable' sortable={false} /> */}
                        {/* <TextField source='status' label='status' /> */}
                        <FunctionField label={t('fieldStatus')} render={r => (r.status === 1 ? 'completed' : 'pending')} />
                        <EditButton />
                    </Datagrid>
                </List>
                <IncidentCreate {...props} />
                <IncidentEdit {...props} />
            </div>
        </div>
    );
};

export default withTranslation()(IndicentList);
