import * as React from 'react';

import { ErrorOutlineOutlined } from '@material-ui/icons';
import ButtonCallApi from './ButtonCallApi';
import { Check } from '@material-ui/icons';

const DialogConfirmCancel = ({ setOpenCancelDialog, cancelRecord, t }) => {
    return (
        <div className='modalCancelOrder'>
            <div className='modalCancelErrorIcon'>
                <ErrorOutlineOutlined fontSize='large' />
            </div>
            <div className='textModalCancelOrder'>
                {t('textDialogConfirmCancelFirstLine')}
                <br /> {t('textDialogConfirmCancelSecondLine')}
            </div>

            <ButtonCallApi
                action='cancelOrder'
                resource='orders'
                successMessage={t('messageSuccesfullyCancel')}
                successAction={() => {
                    setOpenCancelDialog(false);
                }}
                text={t('textConfirm')}
                Icon={Check}
                recordProp={cancelRecord}
            />
        </div>
    );
};

export default DialogConfirmCancel;
