export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_STORE_URL = process.env.REACT_APP_STORE_URL;
export const colors = {
    grey: '#999999',
    blue: '#05b0f1',
    orange: '#f5b26a',
    green: '#1cb394',
    red: '#f25050'
};
// export const colors = {
//     grey: '#ee5564',
//     blue: '#4a86e8',
//     orange: '#f5b26a',
//     green: '#6ac25a',
//     red: '#ee5564'
// };

export const RELOAD_DELIVERY_TIME = 5000; // milliseconds
export const RELOAD_ORDER_TIME = 10000; // milliseconds
export const MAP_DEFAULT_LAT = -22.248946765407986;
export const MAP_DEFAULT_LONG = 166.46014461145876;
export const MAP_KEY = 'AIzaSyDWOkPsOo9gWN_8AZYWv3P5TZ5SwE5y26A';
export const MAP_ICON_SIZE = '35px';
