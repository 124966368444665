import Menu from './Menu';
import { Layout } from 'react-admin';
import { AppLocationContext } from '@react-admin/ra-navigation';

const CustomLayout = props => {
    return (
        <AppLocationContext>
            <Layout {...props} menu={Menu} />
        </AppLocationContext>
    );
};

export default CustomLayout;
