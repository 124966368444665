import {
    //   FIELDS
    TextField,
    FunctionField,
    DateField,
    //   INPUTS
    SelectInput,
    TextInput,
    //   MISC
    List,
    Datagrid,
    Pagination,
    DateInput
} from 'react-admin';
import { userTypes } from '../../data/constants';
import FieldCustomWidth from '../../components/FieldCustomWidth';
import { withTranslation } from 'react-i18next';
import './wallet.css';

const MyPagination = props => <Pagination rowsPerPageOptions={[13]} {...props} />;

const filterDefaultValues = {
    search_field: 'user_detail.email',
    search_value: '',
    search_field: 'user_detail.email',
    user_type: '0',
    start_date: '',
    end_date: ''
};

const WalletListHistory = props => {
    const t = props.t;

    const orderFilters = [
        <SelectInput
            source='search_field'
            choices={[
                { id: 'user_detail.email', name: t('labelEmailUser') },
                { id: 'provider_detail.email', name: t('labelDeliveryMen') },
                { id: 'store_detail.email', name: t('labelEmailStore') },
                { id: 'wallet_description', name: t('labelWalletDescription') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <TextInput source='search_value' label={t('labelSearch')} alwaysOn resettable />,
        <SelectInput
            source='user_type'
            choices={[
                { id: '0', name: t('labelAll') },
                { id: '2', name: t('labelRestaurant') },
                { id: '7', name: t('labelUser') },
                { id: '8', name: t('labelDeliverymen') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <DateInput source='start_date' label={t('labelStartDate')} />,
        <DateInput source='end_date' label={t('labelEndDate')} />
    ];

    return (
        <div className='containerAllWalletHistoryContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('walletTitle')}</div>
                <List
                    {...props}
                    title={t('walletTitle')}
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    filters={orderFilters}
                    filterDefaultValues={filterDefaultValues}
                >
                    <Datagrid>
                        <TextField source='unique_id' label='Id' sortable={false} />
                        <FunctionField
                            label='Type'
                            render={r => {
                                return userTypes[r.user_type];
                            }}
                        />
                        <FunctionField
                            label='Email'
                            render={r => {
                                switch (r.user_type) {
                                    case 2:
                                        return r.store_detail[0].email;
                                    case 7:
                                        return r.user_detail[0].email;
                                    case 2:
                                        return r.provider_detail[0].email;
                                    default:
                                        return '';
                                }
                                return userTypes[r.user_type];
                            }}
                        />
                        <FunctionField
                            label='Date et Heure'
                            render={record => (
                                <DateField
                                    label='Date et Heure'
                                    source='created_at'
                                    showTime
                                    sortable={false}
                                    options={{
                                        day: '2-digit',
                                        month: 'numeric',
                                        year: 'numeric',
                                        timeZone: record.timezone
                                    }}
                                />
                            )}
                        />
                        <TextField source='country_details.country_name' label={t('labelCountry')} sortable={false} />
                        <TextField source='from_currency_code' label={t('labelCurrency')} sortable={false} />
                        <TextField source='wallet_amount' label={t('labelWalletAmount')} sortable={false} />
                        <FunctionField
                            label={t('labelType')}
                            render={r => {
                                return (
                                    <TextField
                                        source='added_wallet'
                                        label={t('labelAddCut')}
                                        sortable={false}
                                        style={{ color: r.wallet_status === 2 ? 'red' : 'green' }}
                                    />
                                );
                            }}
                        />
                        <TextField source='total_wallet_amount' label={t('labelTotalWalletAmount')} sortable={false} />
                        <FieldCustomWidth
                            source='wallet_description'
                            label={t('labelFrom')}
                            width={'200px'}
                            sortable={false}
                        />
                    </Datagrid>
                </List>
            </div>
        </div>
    );
};

export default withTranslation()(WalletListHistory);
