import { useRecordContext } from 'react-admin';
import './FieldCustomWidth.css';

const FieldCustomWidth = ({ source, width, height, text }) => {
    const record = useRecordContext();
    const myClass = {
        // maxWidth: width || '100px',
        // height: height || '40px'
    };

    if (text) {
        return record ? <div style={myClass}>{text}</div> : null;
    }
    return record ? (
        <div style={myClass} className='fieldcustomwidth'>
            {record[source]}
        </div>
    ) : null;
};

export default FieldCustomWidth;
