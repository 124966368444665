import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';
import { withTranslation } from 'react-i18next';
import FieldCustomWidth from '../../components/FieldCustomWidth';

const IncidentShow = props => {
    const t = props.t;
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='unique_id' label={t('labelId')} sortable={false} />
                <TextField source='order_unique_id' label={t('labelOderId')} sortable={false} />
                <FieldCustomWidth source='issue' label={t('labelIssue')} width={'200px'} sortable={false} />
                <FieldCustomWidth source='resolution' label={t('labelResolution')} width={'200px'} sortable={false} />
                <TextField source='added_cut_to_store' label={t('labelAddCutToStore')} sortable={false} />
                <TextField source='added_cut_to_provider' label={t('labelAddCutToProvider')} sortable={false} />
                <TextField source='added_cut_to_user' label={t('labelAddCutToUser')} sortable={false} />
                <TextField source='accountable' label={t('labelAccountable')} sortable={false} />
                <TextField source='status' label={t('labelStatus')} />
            </SimpleShowLayout>
        </Show>
    );
};

export default withTranslation()(IncidentShow);
