import { useState, useEffect } from 'react';
import { Dialog , Switch, FormControlLabel} from '@material-ui/core';
import { ZoomIn, Cancel, DirectionsBike } from '@material-ui/icons';
import FieldStatus from '../../components/FieldStatus';
import FieldType from '../../components/FieldType';
import FieldCustomWidth from '../../components/FieldCustomWidth';
import { makeStyles } from '@material-ui/core';
import { useListContext } from 'react-admin';
import { REACT_APP_API_URL, RELOAD_DELIVERY_TIME } from '../../config';
import './delivery.css';

import {
    //   FIELDS
    TextField,
    FunctionField,
    DateField,
    //   INPUTS
    SelectInput,
    TextInput,
    //   MISC
    List,
    Datagrid,
    Pagination
} from 'react-admin';

// DATA

// COMPONENTS
import ButtonCallApi from '../../components/ButtonCallApi';
import ButtonSimple from '../../components/ButtonSimple';
import OrderDetail from '../../components/OrderDetail';
import Gmaps from '../../components/Gmaps';
import DMSelector from '../../components/DMSelector';
import { withTranslation } from 'react-i18next';
import { callApiIndicators } from '../../data/dataProviderDeliveries';

const MyPagination = props => {
    const t = props.t;
    return (
        <>
            <Pagination rowsPerPageOptions={[10]} {...props} />
        </>
    );
};

const useStyles = makeStyles({
    rowCell: {
        padding: '5px 2px 5px 2px',
        textAlign: 'center'
    }
    // tbody: {
    //     border: 'solid 2px green',
    // }
});

const filterDefaultValues = {
    search_field: 'user_detail.first_name',
    request_status: ''
};

const ListPage = ({ setCurrRecord, currRecord, setDialogData, setDialogDetail, t }) => {
    const classes = useStyles();
    const rowStyle = (record, index) => {
        return {
            backgroundColor: record && currRecord && record.id === currRecord.id ? 'rgb(225, 225, 225)' : 'white',
            transition: 'all 0.5s ease'
        };
    };
    const { data, refetch } = useListContext();
    const [reload, setReload] = useState(true);
    const cancelDisplayStatus = [11, 13, 15, 17, 19, 21, 23];

    useEffect(() => {
        setTimeout(() => {
            refetch();
            setReload(!reload);
        }, RELOAD_DELIVERY_TIME);
    }, [reload]);

    return (
        <Datagrid
            rowClick={(id, basePath, record) => {
                setCurrRecord(record);
                return '';
            }}
            data={data}
            rowStyle={rowStyle}
            classes={{ rowCell: classes.rowCell, tbody: classes.tbody }}
        >
            <TextField source='order_detail.unique_id' sortable={false} label={t('labelId')} />
            <FunctionField
                label='détail'
                render={record => (
                    <ButtonSimple
                        action={() => {
                            setDialogDetail(true);
                            setCurrRecord(record);
                        }}
                        text={t('labelDetail')}
                        Icon={ZoomIn}
                    />
                )}
            />
            <FieldType label='Type' />
            <FunctionField
                label={t('labelRecuperationEstimated')}
                render={record => (
                    <DateField
                        source={'order_detail.estimated_time_for_ready_order'}
                        showTime
                        sortable={false}
                        options={{
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZone: record.timezone
                        }}
                    />
                )}
            />

            <FieldStatus source='order_detail.order_status' label={t('labelStatusOrder')} />

            <FunctionField
                label={t('labelClient')}
                render={r => (
                    <FieldCustomWidth
                        width={'80px'}
                        sortable={false}
                        text={r.user_detail.first_name + ' ' + r.user_detail.last_name}
                    />
                )}
            />
            <FunctionField
                label={t('labelDeliverymen')}
                render={r => (
                    <FieldCustomWidth
                        width={'80px'}
                        sortable={false}
                        text={r.provider_detail ? r.provider_detail.first_name + ' ' + r.provider_detail.last_name : ''}
                    />
                )}
            />
            <TextField label={t('labelRestaurant')} source='store_detail.name' sortable={false} />
            <FieldStatus source='delivery_status' label={t('labelStatusOrder')} />
            <FunctionField
                label={t('labelAction')}
                render={record => (
                    <ButtonCallApi
                        resource='deliveries'
                        text={t('textSelectDM')}
                        action='deliveryManNearby'
                        successAction={(data, rec) => {
                            setDialogData(data);
                            setCurrRecord(rec);
                        }}
                        successMessage=''
                        Icon={DirectionsBike}
                    />
                )}
            />
            <FunctionField
                label={t('labelCancel')}
                render={record => {
                    if (cancelDisplayStatus.includes(record.delivery_status)) {
                        return (
                            <ButtonCallApi
                                resource='deliveries'
                                text={t('textCancelDelivery')}
                                successMessage='canceled'
                                action='cancel'
                                Icon={Cancel}
                            />
                        );
                    }
                }}
            />
        </Datagrid>
    );
};

const DeliveryList = props => {
    const t = props.t;
    const Filters = [
        <SelectInput
            source='search_field'
            choices={[
                { id: 'order_detail.unique_id', name: t('labelId') },
                { id: 'user_detail.first_name', name: t('labelUser') },
                { id: 'provider_detail.first_name', name: t('labelDeliverymen') },
                { id: 'store_detail.name', name: t('labelRestaurant') }
            ]}
            alwaysOn
            allowEmpty={false}
            label={t('labelSearchField')}
        />,
        <TextInput source='search_value' label={t('labelSearch')} alwaysOn resettable />,
        <SelectInput
            source='request_status'
            choices={[
                { id: '', name: 'All' },
                { id: 9, name: 'Assigned to DeliveryMan' },
                { id: 11, name: 'DeliveryMan Accepted' },
                { id: 13, name: 'DeliveryMan Is Coming' },
                { id: 15, name: 'DeliveryMan Arrived' },
                { id: 21, name: 'DeliveryMan Arrived At destination' },
                { id: 19, name: 'Delivering' }
            ]}
            alwaysOn
            allowEmpty={false}
            label={t('labelStatusOfTheOrder')}
        />
    ];
    const [reload, setReload] = useState(true);
    const [indicators, setIndicators] = useState(null);
    const [inactiveProvider, setInactiveProvider] = useState(null);
    const [openInactiveProviderList, setOpenInactiveProviderList] = useState(false);

    async function fetchData() {
        const indicators = await callApiIndicators();
        setIndicators(indicators);
        setInactiveProvider(indicators.inactive_providers_names);
    }

    useEffect(() => {
        setTimeout(() => {
            fetchData();
            setReload(!reload);
        }, RELOAD_DELIVERY_TIME);
    }, [reload]);

    useEffect(() => {
        fetchData();
    }, []);
    const [currRecord, setCurrRecord] = useState(null); // to pass to maps
    const [dialogData, setDialogData] = useState(false); // to pass to modal deliveryMan
    const [dialogDetail, setDialogDetail] = useState(false); // to pass to modal detail
    const [congestionStatus, setCongestionStatus] = useState(false);

    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`${REACT_APP_API_URL}/api/admin/check_app_keys`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ type: 7, device_type: 'android' })
            });

            const json = await result.json();
            setData(json);
        };

        fetchData();
        
    }, []);

    useEffect(() => {
        setCongestionStatus(data?.is_too_busy||false);
    },[data])
    
    const handleCongestionStatus = async () => {
        setCongestionStatus(prevCongestionStatus => !prevCongestionStatus);
      
        setData(prevData => ({ ...prevData, is_too_busy: !prevData.is_too_busy }));
      
        try {
          await fetch(`${REACT_APP_API_URL}/api/setting/update_admin_setting`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ is_too_busy: !congestionStatus })
          });
        } catch (error) {
          console.error('Failed to update congestion status:', error);
        }
      };
      
    return (
        <div className='containerAllDeliveriesContainer'>
            <div className='defaultPageContainer'>
                <div className='titleAndIndicatoresContainer'>
                    <div className='titlePage'>{t('deliveryTitle')}</div>
                    {!!indicators && (
                        <div className='indicatorDeliveryMen'>
                            <div className='containerIndicator'>
                                <span className='titleIndicatorSpan'>{t('indicatorOrderDeliveryMen')}</span>
                                <span className='contentIndicatorSpan'>{indicators.current_requests_per_provider}</span>
                            </div>
                            <div className='containerIndicator'>
                                <span className='titleIndicatorSpan'>{t('indicatorDeliveryMenWithoutOrder')}</span>
                                <span className='contentIndicatorSpan'>{indicators.providers_without_requests}</span>
                            </div>
                            <div className='containerIndicator'>
                                <span className='titleIndicatorSpan'>{t('indicatorDeliveryMenOnlineAndActive')}</span>
                                <span className='contentIndicatorSpan'>{indicators.active_providers}</span>
                            </div>
                            <div onClick={() => setOpenInactiveProviderList(true)} className='containerIndicator'>
                                <span className='titleIndicatorSpan'>{t('indicatorDeliveryMenOnlineAndInactive')}</span>
                                <span className='contentIndicatorSpan'>{indicators.inactive_providers}</span>
                            </div>
                        </div>
                    )}
                    <FormControlLabel
                        control={<Switch checked={congestionStatus} onChange={handleCongestionStatus} className='redSwitch'/>}
                        label={congestionStatus ? 'Congestion activée' : 'Activer congestion'}
                        labelPlacement='top'
                        className='switchPopup'
                    />
                </div>

                <List
                    {...props}
                    title={t('deliveryTitle')}
                    bulkActionButtons={false}
                    pagination={<MyPagination {...props} />}
                    actions={null}
                    aside={<Gmaps t={t} record={currRecord} />}
                    filters={Filters}
                    filterDefaultValues={filterDefaultValues}
                    empty={false}
                >
                    <ListPage
                        setCurrRecord={setCurrRecord}
                        currRecord={currRecord}
                        setDialogData={setDialogData}
                        setDialogDetail={setDialogDetail}
                        t={props.t}
                    />
                </List>
                {/* # # # # # # # # # # # # #  After clicking on Delivery Man selection  # # # # # # # # # # # # # */}
                <Dialog
                    className='dmSelectorPopup'
                    open={!!dialogData ? true : false}
                    onClose={() => {
                        setDialogData(null);
                    }}
                >
                    {dialogData && currRecord && (
                        <DMSelector
                            t={t}
                            data={dialogData.provider_detail}
                            setDialogData={setDialogData}
                            currRequest={currRecord}
                        />
                    )}
                </Dialog>
                {/* # # # # # # # # # # # # #  After clicking on Detail  # # # # # # # # # # # # # */}
                <Dialog
                    open={dialogDetail}
                    onClose={() => {
                        setDialogDetail(false);
                    }}
                    maxWidth={false}
                >
                    <OrderDetail t={t} data={currRecord} />
                </Dialog>
            </div>
            <Dialog open={openInactiveProviderList} onClose={() => setOpenInactiveProviderList(false)} maxWidth={false}>
                <div className='inactiveProviderList'>
                    {inactiveProvider !== null &&
                        inactiveProvider.length !== 0 &&
                        inactiveProvider.map(inactive => <div className='inactiveProvider'>{inactive}</div>)}
                </div>
            </Dialog>
        </div>
    );
};

export default withTranslation()(DeliveryList);
