import { useRedirect } from 'react-admin';
import { ReportProblem } from '@material-ui/icons';
import ButtonCallApi from './ButtonCallApi';

const ButtonIncident = ({ orderId, orderUniqueId }) => {
    const redirect = useRedirect();
    return (
        <ButtonCallApi
            resource='incidents'
            action='getOrdersIncidentsDetail'
            text='Incident'
            successMessage={null}
            successAction={() => {
                redirect('edit', '/incidents', orderId);
            }}
            failAction={() => {
                redirect('create', '/incidents', 1, 1, {
                    record: { order_id: orderId, order_unique_id: orderUniqueId }
                });
            }}
            Icon={ReportProblem}
            otherParameters={orderId}
            noErrorNotif
        />
    );
};

export default ButtonIncident;
