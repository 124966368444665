import { AttachMoney, HowToReg, Cancel, Notifications, RestaurantMenuOutlined } from '@material-ui/icons';
import { TextField } from 'react-admin';
import { withTranslation } from 'react-i18next';

import ButtonCallApi from '../../components/ButtonCallApi';
import ButtonCallApiInput from '../../components/ButtonCallApiInput';
import ButtonSimple from '../../components/ButtonSimple';
import ToolbarCustom from '../../components/ToolBarCustom';
import { REACT_APP_STORE_URL } from '../../config';

const StoreShow = props => {
    const t = props.t;
    const { server_token } = localStorage.getItem('auth');
    return (
        <div>
            <ToolbarCustom className='toolbarCustom'>
                <ButtonSimple
                    text={t('restaurantView')}
                    action={() => {
                        window.open(
                            REACT_APP_STORE_URL +
                                '/?token=' +
                                props.record.server_token +
                                '&store=' +
                                props.record._id +
                                '&adminToken=' +
                                server_token
                        );
                    }}
                    Icon={RestaurantMenuOutlined}
                />
                <ButtonCallApi
                    action='approveDecline'
                    resource='stores'
                    text={props.record.is_approved ? t('textDecline') : t('textApprove')}
                    otherParameters={props.record.is_approved ? 1 : 2}
                    successMessage={
                        props.record.is_approved ? t('messageSuccessfullyDeclined') : t('messageSuccessfullyApproved')
                    }
                    Icon={props.record.is_approved ? Cancel : HowToReg}
                />
                <ButtonCallApiInput
                    action='sendNotif'
                    resource='stores'
                    successMessage={t('messageSuccesfullySendNotification')}
                    text={t('textNotification')}
                    Icon={Notifications}
                />

                <ButtonCallApiInput
                    resource='stores'
                    successMessage={t('messageSuccesfullyAddWallet')}
                    action='addWallet'
                    text={t('textAddWallet')}
                    Icon={AttachMoney}
                />
                <div>{t('textCurrentWalletAmount')}</div>
                <TextField label={t('labelWallet')} source='wallet' sortable={false} />
            </ToolbarCustom>
        </div>
    );
};

export default withTranslation()(StoreShow);
