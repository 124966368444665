import { AttachMoney, HowToReg, Cancel, Notifications } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

import ButtonCallApi from '../../components/ButtonCallApi';
import ButtonCallApiInput from '../../components/ButtonCallApiInput';
import ToolbarCustom from '../../components/ToolBarCustom';

const UserShow = props => {
    const t = props.t
    return (
        <ToolbarCustom className='toolbarCustom'>
            <ButtonCallApi
                action='approveDecline'
                resource='users'
                text={props.record.is_approved ? t('textDecline') : t('textApprove')}
                otherParameters={props.record.is_approved ? 1 : 2}
                successMessage={props.record.is_approved ? t('messageSuccessfullyDeclined') : t('messageSuccessfullyApproved')}
                Icon={props.record.is_approved ? Cancel : HowToReg}
            />
            <ButtonCallApiInput
                resource='users'
                successMessage={t('messageSuccesfullyAddWallet')}
                action='addWallet'
                text={t('textAddWallet')}
                Icon={AttachMoney}
            />
            <ButtonCallApiInput
                action='sendNotif'
                resource='users'
                successMessage='Successfull sent notification'
                text='send notif'
                Icon={Notifications}
            />
        </ToolbarCustom>
    );
};

export default withTranslation()(UserShow);
